import '../App.css';

function ETQ({ experiences }) {
    const { title, date, keywords, content } = experiences;

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h2 className="title">Implementation Consultant at ETQ</h2>
                <h3 className="date">Feb 2023 - Present</h3>
            </div>
            <ul>
                <li className="text">Led requirements gathering sessions to understand the customer’s current business processes and future expectations. </li>
                <li className="text">Mapped the business requirements to ETQ’s quality and compliance management software with the help of a SIPOC to create a Software Design Document (SDD). </li>
                <li className="text">Orchestrated on-site as well as remote training sessions to help customers understand, utilize and maintain their configured systems. </li>
                <li className="text">Organized weekly meetings with the project stakeholders, including product and cross-functional teams as needed, to deliver status updates, tackle potential risks and prevent scope creep. </li>
                <li className="text">Ensured project documents follow standards and are complete, current and stored appropriately </li>
            </ul>
            <div className="titleRow">
                <h2 className="title">Technical Implementation Consultant at ETQ</h2>
                <h3 class="date">Nov 2021 - Feb 2023</h3>
            </div>
            <ul>
                <li className="text">Learned the ins and outs of ETQs software, associated proprietary scripting language and SQL to deliver highly resilient configurations to our clients. </li>
                <li className="text">Effectively bridged the gap of communicating technical requirements to clients in companies from global fortune 500 to small US based enterprises.</li>
                <li className="text">Led User Training Sessions to 100s of clients with an average rating given of 4.5/5 on the courses.</li>
                <li className="text">Consistently received praise from clients, coworkers and managers on my promptness, adaptability and proactivity.</li>
            </ul>
        </div>
    );
}


export default ETQ;
