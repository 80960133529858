import '../App.css';
import ImageCarousel from '../ImageCarousel.js';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function Onda({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    const appImages = [
        'https://media.louisromier.com/Onda/homeScreen.PNG',
        'https://media.louisromier.com/Onda/searchScreen.PNG',
        'https://media.louisromier.com/Onda/listingsScreen.PNG',
        'https://media.louisromier.com/Onda/listingInfoScreen.PNG',
        'https://media.louisromier.com/Onda/checkoutScreen.PNG',
        'https://media.louisromier.com/Onda/boardScreen.PNG',
        'https://media.louisromier.com/Onda/addBoardScreen.PNG'
    ];

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h2 className="title">{title[language]}</h2>
                <h3 className="date">{date}</h3>
            </div>
            <ul>
                <li className="text">
                    Worked under multiple job titles to establish Onda, a peer to peer surfboard rental company.
                </li>
                <li className="text">
                    Coded the application in react native and got it approved on the Apple app store within 4 months.
                </li>
                <li className="text">
                    Collaborated with local surf shops, shapers and websites to drive user acceptance through grassroots marketing, social media and online advertising.
                </li>
                <li className="text">
                    Created the Onda website including legal information such as terms of service, insurance guarantee and our privacy policy.
                </li>
            </ul>
            <h2 className="title">Onda Screenshots</h2>
            <ImageCarousel imageUrls={appImages} />
        </div>
    );
}

export default Onda;
