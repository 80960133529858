import '../App.css';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function Univesity({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h2 className="title">{title[language]}</h2>
                <h3 className="date">{date}</h3>
            </div>
            <div className="titleRow">
                <h2 className="title">Minor in Computer Science</h2>
                <h3 className="date"></h3>
            </div>
            <ul>
                <li className="text">
                    Focus in Management Information Systems
                </li>
                <li className="text">
                    Activities: Business and Technology Club, Treasurer of Snowboard Club
                </li>
                <li className="text">
                    Studied abroad for a semester in Sydney, Australia.
                </li>
            </ul>
        </div>
    );
}

export default Univesity;