import '../App.css';
import React, { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function About() {
  const { language } = useContext(LanguageContext);

  const translations = {
    about: {
      english: 'About',
      french: 'A Propos de Moi',
      spanish: 'Sobre'
    },
    p1: {
      english: 'I was born and spent my early childhood in a quaint village in France. At the age of five, I relocated to Miami, marking the beginning of my American journey. This significant transition instilled in me an insatiable curiosity. This curiosity has led me to deeply appreciate the complexities of the world around me and continuously seek ways to better understand them.',
      french: "Je suis né et j'ai passé ma petite enfance dans un village pittoresque français. A l'âge de cinq ans, j'ai déménagé à Miami, marquant le début de mon voyage américain. Cette transition importante m'a inculqué une curiosité insatiable. Cette curiosité m'a amené à apprécier les complexités du monde qui m’entoure et chercher continuellement des moyens de mieux les comprendre.",
      spanish: 'Nací y pasé mi primera infancia en un pintoresco pueblo de Francia. A la edad de cinco años, Me mudé a Miami, lo que marcó el comienzo de mi viaje por Estados Unidos. Esta importante transición Me inculcó una curiosidad insaciable. Esta curiosidad me ha llevado a profundizar Aprecio las complejidades del mundo que me rodea y busco continuamente formas de comprenderlas mejor.'
    },
    contact: {
      english: 'Contact',
      french: 'Contact',
      spanish: 'Contacto'
    }
  };

  return (
    <div className="aboutContainer">

      <div className="image-container">

        <img src="/headshot.png" alt="Headshot" style={{ width: '100%', height: 'auto' }} />

      </div>

      <div className="content-container">

        <h3 className="title">{translations.about[language]}</h3>

        <div className="introduction">
          <p className="text">{translations.p1[language]}</p>
        </div>

        <div className="contact-container">
          <h3 className="title">{translations.contact[language]}</h3>
          <p className="text"><a href="mailto:lxr@louisromier.com" className="download-link">lxr@louisromier.com</a></p>
        </div>

      </div>
    </div >
  );
}

export default About;