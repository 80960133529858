import React from 'react';
import './App.css';

const RadioButton = ({ label, checked, onChange, value }) => {
    return (
        <label className="custom-radio">
            <input
                type="radio"
                value={value}
                checked={checked}
                onChange={onChange}
                name="categoryFilter"
            />
            <span className="radio-indicator"></span>
            {label}
        </label>
    );
};

export default RadioButton;