import '../App.css';

function Shaping({ experiences }) {
  const exp = experiences["SurfboardShaping"];

  return (
    <div className="experience-container">
      <div className="titleRow">
        <h2 className="title">Surfboard Shaping</h2>
        <h3 className="date">Present</h3>
      </div>
      <p className='text'>
        I have recently started hand shaping surfboards.
      </p>
      <div className="gallery">
        {/* Render images or other specific content */}
      </div>
    </div>
  );
}

export default Shaping;