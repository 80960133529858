import '../App.css';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function Article3({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    return (
        <div className="experience-container">

            <div className="titleRow">
                <h1 className="title">{title[language]}</h1>
                <h3 className="date">{date}</h3>
            </div>

            <p className="text">The unexpected occurrences of the novel SARS-CoV-2 resulted in drastic economic shifts throughout the world. An effect of this is the rise of the isolation economy. The isolation economy is categorized by a reduction of the movement out of our households in order to fulfill our daily needs. It has now become common to get your groceries delivered to you via Amazon, exercise at home on your new Peloton bike and work through Zoom tele-meetings while in the comfort of your own homes. Since the beginning of the quarantining protocols, the rise of the isolation economy seems to have coincided with a fall in the sharing economy. Does this result in an inability for the sharing economy to bounce back to its pre-quarantine glory days?</p>
            <p className="text">To answer this, first of all, terms commonly convoluted with the sharing economy need to be defined and understood.</p>
            <ul>
                <li className='text'>The sharing economy focuses on the potentially monetized sharing of underutilized assets to improve efficiency, sustainability and community.</li>
                <li className='text'>The on-demand economy focuses on the immediate provision of goods and services.</li>
                <li className='text'>The gig economy focuses on the income generation of individuals in exchange for completion of individual tasks and projects.</li>
                <li className='text'>The freelance economy is similar to the gig economy but usually involves longer and deeper projects.</li>
                <li className='text'>The peer economy focuses on the peer to peer networks used in product creation, delivery of services, funding and more.</li>
                <li className='text'>The crowd economy focuses on economic models powered by the crowd, such as, but not limited to crowdfunding and crowdsourcing.</li>
            </ul>
            <p className="text">Companies like Zipcar, BlaBlaCar and Couchsurfing were among the first to be part of the sharing economy, when it only referred to the sharing of an underutilized good. But as the sharing economy has grown into a trend in modern society, many companies have used it to describe themselves wrongfully in an attempt to become a part of the trend. For example, a leased car working for Uber is not part of the sharing economy as it would not have been on the road otherwise. But on the other hand, the Uber pool service is part of the sharing economy as it is more efficiently using an underutilized good. The effects of this economy become much more confusing and hard to track as countries like China describe their 21st century transformation narrative largely with the help of the sharing economy; describing communication as the sharing of news, trade as the sharing of imports and exports and education as the sharing of information. The sharing economy has been getting very convoluted and has been long overdue for a return to its original values of community, sustainability and reliability. This global pandemic might be the shock necessary to redefine the sharing economy and bring it back to its roots.</p>

            <h2 className="title">Direct Effects on the Sharing Economy Due to COVID-19</h2>
            <p className="text">Many companies that are part of the sharing economy have felt negative consequences as a result of the novel coronavirus. Companies like Airbnb, Lyft and Uber have all seen a drop in business of 65-80% and have been pushed to furlough 17-25% of their employees. This came at a time when these companies were pushing to finally make a profit. This is due to their business strategy aimed at growing as fast as possible and only then focusing on generating a profit. Uber, for example, was aiming to turn a profit by the end of 2020. These plans have been derailed as these companies are now striving to stay afloat. These companies now have to direct their initiatives to cutting weak points in their business models and hoping that activity will bounce back quickly. </p>
            <p className="text">Although these figures paint a grim image for the sharing economy, they are actually hopeful for the sharing economy when compared to their traditional counterparts. For example, if we look at Marriott International, the largest hotel chain in the world, the figures are even worse. Not only have they seen similar drops in business activity; including countless cancellations, but they have also furloughed closer to 80% of their employees. This change can be attributed to how sharing economy companies hold less physical assets and are therefore more resistant to drastic changes in demand. The negative sharing economy figures above might then be attributed more so as a result of the negative downturn of the hospitality and transportation industry as opposed to the sharing economy as a whole.</p>

            <h2 className="title">Reinvention and Hope for the Sharing Economy</h2>
            <p className="text">As multiple countries have been able to contain the pandemic throughout the past couple months, certain sharing economy companies have seen hopeful changes. These hopeful changes are a result of a change in the company direction, consumer habits and a quick bounce back to previous levels of activity.</p>
            <ul>
                <li className='text'>Globe is a six person startup begun a year ago to offer users daytime short-term rentals of a house or part of a house. When the pandemic hit, Emmanuel Bamfo, the boss of Globe, decided to adapt the service to this unprecedented time. The app was changed to just focus on renting out whole empty houses and flats for an hourly rate. This allowed users renting the space a respite to be able to work remotely in a calm environment. Since then, they have acquired 25,000 new users and the waiting list for renters worldwide has skyrocketed to 113,000. They currently have over 10,000 hosts who have already gone through the approval process.</li>
                <li className='text'>BlaBlaCar, a long distance peer to peer ride sharing platform has been forced to reconsider what it values during quarantine. A group of drivers for BlaBlaCar set up BlaBlaHelp to deliver essential goods to people such as groceries, reinforcing the community aspect of a sharing economy. As lockdown restrictions are lifting in Europe, Nicolas Brusson, CEO of BlaBlaCar, has said that activity has quickly picked up again as he reckons the effects of the pandemic will further increase demand for cheap rides.</li>
                <li className='text'>Another sharing platform that needed to come back to the values of the sharing economy in order to prosper is Olio, a London based service pushing to eliminate unnecessary waste by allowing users to share food and other items. They incorporated contactless delivery as soon as they foresaw problems with their business model and have since seen a 50% increase in food sharing and 200% in all other things.</li>
                <li className='text'>Things were looking grim throughout quarantine for Turo, a peer to peer car sharing platform. Since the stay at home orders were put into place, Turo was experiencing a 75% decline in year to year growth. But since early June, demand has undergone a shocking bounce back and they are now growing again on year to year growth.</li>
                <li className='text'>UberEats has seen increased demand as well as increased restaurant signups to the platform throughout the global pandemic.</li>
                <li className='text'>Airbnb has seen a trend in increasing the average length of stays, and a higher portion of people renting places within 200 miles of their home.</li>
                <li className='text'>Bird, the scooter sharing platform, has seen longer average ride lengths compared to pre-pandemic lengths.</li>
            </ul>

            <h2 className="title">The Isolation and Sharing Economy will Coexist and Prosper</h2>
            <p className="text">One of the driving forces for the isolation economy was the unmet need of people to feel a sense of community during social distancing protocols. Being disconnected from people has been shown to lead to detrimental mental health states which has led people to create ingenious ways of staying connected with family and friends. Some people have started happy hours and family meet ups facilitated by the use of technology. This trend along with trends of greater amounts of remote work, larger family homes and smaller office spaces will likely continue into the future. Although society is currently satisfied with this. there will be a point where we need to reconnect with people in person. We are currently on the extreme end of the spectrum towards the isolation economy, but as we want to gain more than what we are able to get from online interactions, we will find a balance between the isolation and sharing economy. Through online communication we are unable to take in as much information as we do with in person interactions due in part to how heavily we rely on body language to make sense of what others say to us. This is why the isolation and sharing economy both will prosper together once we find a balance between them. This will allow us to live comfortably in our own homes and also feel connected in a community regardless of race, gender, or socio economic status. Apart from a shared sense of community, the sharing economy will be crucial in reducing waste and focusing on sustainability to combat climate change in years to come.</p>

            <h2 className="title">References</h2>
            <ul>
                <li className="text"><a href="https://www.npr.org/2020/06/01/865516558/who-dares-to-rent-a-dress-now-coronavirus-upends-the-sharing-economy">Bond, Shannon. “Who Dares To Rent A Dress Now? Coronavirus Upends The Sharing Economy.” NPR, NPR, 1 June 2020</a></li>
                <li className="text"><a href="https://www.nytimes.com/2020/05/07/technology/the-results-are-in-for-the-sharing-economy-they-are-ugly.html">Conger, Kate, and Erin Griffith. “The Results Are In for the Sharing Economy. They Are Ugly.” The New York Times, The New York Times, 7 May 2020</a></li>
                <li className="text"><a href="https://www.kqed.org/news/11813671/hotel-chain-seeks-bailout-money-while-workers-struggle-to-survive">Harnett, Sam. “Hotel Chain Seeks Bailout Money While Workers Struggle to Survive.” KQED, 30 Apr. 1970</a></li>
                <li className="text"><a href="https://arstechnica.com/cars/2020/06/car-sharing-to-get-away-from-the-pandemic-according-to-turo-users/">Jonathan M. Gitlin. “Car-Sharing to Get Away from the Pandemic, According to Turo Users.” Ars Technica, 23 June 2020</a></li>
                <li className="text"><a href="https://nypost.com/2020/05/29/rent-by-the-hour-app-globe-sees-spike-during-coronavirus-lockdown/">Kussin, Zachary. “People Rent by-the-Hour Spaces to Escape Loved Ones, Homes during Lockdown.” New York Post, New York Post, 30 May 2020</a></li>
                <li className="text"><a href="https://news.umich.edu/covid-19-implications-for-the-sharing-economy-companies/">Larochelle, Claudia. “Erik Gordon: COVID-19 Implications for the Sharing Economy Companies.” University of Michigan News, 4 Aug. 2020</a></li>
                <li className="text"><a href="https://www.forbes.com/sites/kmehta/2020/03/23/welcome-to-the-isolation-economy-goodbye-sharing-economy/">Mehta, Kumar. “Welcome To The Isolation Economy (Goodbye Sharing Economy).” Forbes, Forbes Magazine, 23 Mar. 2020</a></li>
                <li className="text"><a href="https://medium.com/swlh/coronavirus-the-end-of-the-sharing-economy-or-a-new-beginning-a142acbb7130">Rinne, April. “Coronavirus: The End of the Sharing Economy, or a New Beginning?” Medium, The Startup, 6 Apr. 2020</a></li>
                <li className="text"><a href="https://www.economist.com/business/2020/06/04/the-sharing-economy-will-have-to-change">“The Sharing Economy Will Have to Change.” The Economist, The Economist Newspaper</a></li>
                <li className="text"><a href="https://www.worldpoliticsreview.com/articles/28893/what-the-coronavirus-pandemic-means-for-the-sharing-economy-business-model">Toyama, Kentaro. “The Sharing Economy Will Survive the Pandemic. Is That a Good Thing?” World Politics Review, 7 July 2020</a></li>
                <li className="text"><a href="https://www.weforum.org/agenda/2017/12/when-is-sharing-not-really-sharing/">Written by April Rinne. “What Exactly Is the Sharing Economy?” World Economic Forum</a></li>
            </ul>

        </div>
    );
}

export default Article3;