import '../App.css';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function Article2({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    return (
        <div className="experience-container">

            <div className="titleRow">
                <h1 className="title">{title[language]}</h1>
                <h3 className="date">{date}</h3>
            </div>

            <p className="text">Stephen Hawking once speculated that the widespread use of artificial intelligence (AI) would one day change the role that humans play in society. This describes the “hollowing of the workforce,” in which new computer technologies increase the amount of low skill level jobs, while creating few high level jobs and reducing mid level jobs. As AI has progressively become pushed into the mainstream, it has led many people to develop an opinion on its use. Most people have developed their opinions through their personal perceived balance of the positive and negative effects of AI. Some of the positive effects of AI could be creating efficiencies and new job sectors, while negative effects are a dramatic reduction in jobs and AI potentially getting out of control if given misaligned incentives.</p>
            <p className="text">AI is still very much in its infancy, but we have already seen how much it could benefit our society. For example, AI is already being utilized by a majority of the world’s biggest companies for a vast variety of tasks. American Express uses it to detect fraud in real time. Infervision in China uses it to help doctors diagnose cancers more effectively. Volvo uses it to predict which parts of a vehicle are more prone to failure, keeping them on top of the safety ratings year after year. GE Power is using it to enable predictive maintenance and power in order to ultimately be able to create a digital power plant. Walmart is even using it in certain stores along with facial recognition software to analyze if the buyers are sad or happy. There are countless possible uses for AI to find and create efficiencies within existing sectors. All that it needs is data to learn and extrapolate from.</p>

            <h2 className="title">The Creativity Problem</h2>
            <p className="text">Many people who understand the potential disruption this technology could cause choose to draw a line for it when it comes to creative uses. They believe our humane ability to create something new will keep us distinguished from AI. As AI is currently, it is not able to create something without working in conjunction with human guidance. Although given the exponential nature of Moore’s Law and increases in new technology, it would be naive to say that AI will never be able to create on it’s own. AI is already being widely used as a tool to increase the limit of our own creativity in fields ranging from the creation of tv shows, to the design of a car chassis and redefining modern art. </p>

            <h2 className="title">Here are some of the most interesting uses of AI as a creative tool I have found.</h2>

            <h2 className="title">Media</h2>
            <p className="text">Netflix has been using AI to process big data in order to create shows forecasted to perform well. House of Cards, the popular political show starring Robin Wright and Kevin Spacey, was created in this manner. This allowed Netflix to commit to two seasons of the show before even testing a pilot. </p>
            <p className="text">AI is also abundant in online news. A company by the name of Urbs Media has been using AI to generate news articles automatically to fill a gap of articles unfulfilled by humans. The articles written by these robots total more than 30,000 articles a month, and are fed by data from the government, public services, and local authorities. This data along with natural language generation technology is what allows them to write these articles.</p>

            <h2 className="title">Music</h2>
            <p className="text">David Cope, a recognized composer, created one of the first uses of AI to help compose music. In the 80s during a composer’s block, he decided to mess around with AI to write music in his style in a project titled Experiments in Musical Intelligence or EMI. His program would try its hand at Cope’s composing style, and Cope would use this as inspiration to compose more music. After more work on the program, Cope was able to use the AI to replicate orchestras in the style of Mozart, Palestrina, Albinoni, Brahms, Debussy, Bach, Rachmaninoff, Chopin, Stravinsky, and Bartok.</p>
            <p className="text">IBM’s famed Watson, who beat a human in Jeopardy, also has a division focused on generating music. Watson BEAT is able to collect data on pitch, time, key signatures and note sequences to find out what a listener might want to hear. It is also very useful to inspire artists. Grammy nominated songwriter/producer Alexander Grant used BEAT to help inspire the single Not Easy, which reached number four on the Itunes Hot Chart in 2016 within 48 hours of its release. He believes AI has a chance to help musicians better tailor their music towards a certain audience and allow them to push creative boundaries previously set.</p>
            <p className="text">Taryn Southern is an artist who has composed and produced her first solo album utilizing AI. She utilized an open AI platform called Amper Music in which you feed in parameters such as genre, beats per minute, and instruments you want to use. The program then spits out disjointed verses to be rearranged into the final song. </p>

            <h2 className="title">Art</h2>
            <p className="text">Daniel Ambrosi is recognized as one of the founding creators of AI art. He was inspired by Google’s DeepDream to process images through an AI algorithm that would try to make sense of it in different ways. Initially, DeepDream was begun by Google engineers to try to visualize the inner workings of Deep Learning artificial intelligence models. Ambrosi describes his computational photography in Dreamscapes as a way to better describe the world. Not just visually but also viscerally and cognitively. </p>
            <p className="text"><a href="https://www.danielambrosi.com/Dreamscapes/">https://www.danielambrosi.com/Dreamscapes/</a></p>
            <p className="text"><a href="https://vimeo.com/216295790#at=178">https://vimeo.com/216295790#at=178</a></p>
            <p className="text">Christie’s recently had an AI generated portrait up for auction which ultimately sold for $432,500. This portrait was made by a Paris based collective named Obvious. It was made by two opposing sides of AI in a generative adversarial network. This network uses both a generator and a discriminator who work in conjunction with one another. The generator makes portraits based on the thousands of portraits it was fed. The resulting paintings are fed to the discriminator. If the discriminator is unable to recognize if the portrait was machine or human made, the portrait has passed the test. This shows how AI algorithms can emulate human creativity.</p>
            <p className="text">IBM’s Watson created the world’s first thinking structure inspired from Gaudi’s works. Watson was given hundreds of images of Gaudi’s work and local architecture to pick apart design elements, objects and trends. Watson was also given documents on Gaudi and other local architects, song lyrics and historical articles in order to pick apart more patterns to use in the sculpture. This is where Watson was able to get the geometric patterns to build the structure. The structure has recognizable organic aspects of beehives and shells, and works with an interactive screen.</p>
            <p className="text">Refik Anadol trains his AI on large amounts of images which are almost exclusively natural landscapes in order to create a morphing landscape fully generated by the AI. The AI is essentially trying to recreate an image from the images it learned on.</p>
            <p className="text"><a href="https://www.instagram.com/refikanadol/?hl=en">https://www.instagram.com/refikanadol/?hl=en</a></p>
            <p className="text">Pindar Van Arman creates robots with robotic arms that utilize 3D printing and AI in order to create paintings. His project is called Cloud Painter, and it is his way of trying to decipher how his own creativity works through outsourcing it to the robot to better analyze the process. Cloud Painter has grown as a painter since its first paintings made of simple lines. Its recent work has gained praise from NYC art critic, Jerry Saltz.</p>
            <p className="text">Gene Kogan:
                First decentralized autonomous artist <a href="https://www.instagram.com/refikanadol/?hl=en">https://genekogan.com/</a>
                Memo Atken:
                Uses AI to reflect ourselves and how we make sense of the world <a href="https://www.instagram.com/refikanadol/?hl=en">http://www.memo.tv/works/</a>
                The Painting Fool:
                Has made art after reading articles on a subject <a href="https://www.instagram.com/refikanadol/?hl=en">http://www.thepaintingfool.com/index.html</a></p>

            <h2 className="title">Misc.</h2>
            <p className="text">IBM’s Watson has a division by the name of Chef Watson that helps people develop recipes and generate food combinations to create completely unique flavors. A cookbook has even been made with recipes Watson has helped create.</p>
            <p className="text">Primordial Research Project has designed a car chassis using AI. They began with a bare bones looking AC Cobra with all types of sensors throughout the chassis. Utilizing these sensors they were able to feed the data of the car going around a track to the AI. The AI was then asked to generate a more efficient model of the chassis given these inputs. The result is an organic looking chassis that is unmistakably not designed by a human. The new chassis was able to reduce the overall weight of the car increasing the power to weight ratio of the car.</p>

            <h2 className="title">The AI Renaissance</h2>
            <p className="text">As the examples show, the use of AI as a tool to expand creativity has ushered in a new era of art. From the Renaissance to modern times, countless art styles have come and gone such as Pop Art, Surrealism, Romanticism, Dada, Cubism and many more. Art has had a tendency to move towards the abstract since the Renaissance whether it be through experimenting with color, shape or perspective. This is why much of the AI generated art also tends towards the abstract. It is simply following the logical flow of how art will progress throughout time. </p>

            <h2 className="title">Artificial Intelligence as a Creative Tool</h2>
            <p className="text">AI already has the potential to work under human guidance in order to achieve new levels of creativity previously unheard of. Potentially one day in the future, AI might be able to create without human guidance. But as of now, AI will continue to be used as a tool which allows us to achieve more than we could alone, further combining the incorporation of technology in our everyday life.</p>

            <h2 className="title">References</h2>
            <ul>
                <li className="text"><a href="https://www.forbes.com/sites/bernardmarr/2018/04/30/27-incredible-examples-of-ai-and-machine-learning-in-practice/">Marr, Bernard. “27 Incredible Examples Of AI And Machine Learning In Practice.” Forbes, Forbes Magazine, 12 Dec. 2018</a></li>
                <li className="text"><a href="https://www.ibm.com/blogs/watson/2016/01/chef-watson-has-arrived-and-is-ready-to-help-you-cook/">“Chef Watson Has Arrived and Is Ready to Help You Cook.” Watson Blog, 29 May 2019</a></li>
                <li className="text"><a href="https://www.sciencedirect.com/science/article/abs/pii/S0950705196010490">Schmid, Klaus. “Making AI Systems More Creative: the IPC-Model.” Knowledge-Based Systems, Elsevier, 19 Feb. 1999</a></li>
                <li className="text"><a href="https://www.bernardmarr.com/default.asp?contentID=719">“Big Data and Music: Creating Hit Records with Machine Learning.” Bernard Marr</a></li>
                <li className="text"><a href="https://www.forbes.com/sites/forbestechcouncil/2018/08/28/how-far-are-we-from-truly-human-like-ai/">Mills, Terence. “Council Post: How Far Are We From Truly Human-Like AI?” Forbes, Forbes Magazine, 29 Aug. 2018</a></li>
                <li className="text"><a href="https://www.forbes.com/sites/cognitiveworld/2019/06/10/how-far-are-we-from-achieving-artificial-general-intelligence/">Joshi, Naveen. “How Far Are We From Achieving Artificial General Intelligence?” Forbes, Forbes Magazine, 10 June 2019</a></li>
                <li className="text"><a href="https://refikanadol.com/">“Media Artist + Director.” Refik Anadol</a></li>
                <li className="text"><a href="https://www.bbvaopenmind.com/en/articles/artificial-intelligence-and-the-arts-toward-computational-creativity/">Ramón López de Mántaras. “Artificial Intelligence and the Arts: Toward Computational Creativity.” OpenMind</a></li>
                <li className="text"><a href="https://aiartists.org/">“The Top 25 AI Artists of 2020.” AIArtists.org</a></li>
                <li className="text"><a href="https://news.artnet.com/art-world/ai-art-comes-to-market-is-it-worth-the-hype-1352011">Schneider, Tim. “Has Artificial Intelligence Given Us the Next Great Art Movement? Experts Say Slow Down, the 'Field Is in Its Infancy'.” Artnet News, 25 Sept. 2018</a></li>
                <li className="text"><a href="https://futureoflife.org/background/benefits-risks-of-artificial-intelligence/?cn-reloaded=1">Rohde, Klaus. “Benefits & Risks of Artificial Intelligence.” Future of Life Institute</a></li>
                <li className="text"><a href="https://www.computer.org/publications/tech-news/closer-than-you-might-think/debating-artificial-intelligence">“Debating Artificial Intelligence: IEEE Computer Society.” IEEE Computer Society Debating Artificial Intelligence Comments</a></li>
                <li className="text"><a href="https://www.fastcompany.com/3054028/inside-the-hack-rod-the-worlds-first-ai-designed-car">Terdiman, Daniel. “Inside The Hack Rod, The World's First AI-Designed Car.” Fast Company, Fast Company, 2 Dec. 2015</a></li>
                <li className="text"><a href="https://www.christies.com/features/A-collaboration-between-two-artists-one-human-one-a-machine-9332-1.aspx">Person. “Is Artificial Intelligence Set to Become Art's next Medium?: Christie's.” The First Piece of AI-Generated Art to Come to Auction | Christie's, Christies, 12 Dec. 2018</a></li>
                <li className="text"><a href="https://www.ibm.com/blogs/watson/2017/02/cognitive-artform-watson-pays-homage-gaudi-mobile-world-congress-2017/">“The Cognitive Artform: Watson Pays Homage to Gaudi at Mobile World Congress 2017.” Watson Blog, 30 May 2019</a></li>
                <li className="text"><a href="https://www.memo.tv/works/">“Work – Memo Akten: Mehmet Selim Akten: The Mega Super Awesome Visuals Company.” Memo Akten | Mehmet Selim Akten | The Mega Super Awesome Visuals Company</a></li>
                <li className="text"><a href="https://www.danielambrosi.com/Dreamscapes/">“Dreamscapes: A Collaboration of Nature, Man, and Machine - Daniel Ambrosi.” Daniel Ambrosi Dreamscapes A Collaboration of Nature Man and Machine Gallery RSS</a></li>
                <li className="text"><a href="https://www.tarynsouthern.com/album">“Album.” Taryn</a></li>
                <li className="text"><a href="https://www.theverge.com/2017/8/27/16197196/taryn-southern-album-artificial-intelligence-interview">Plaugic, Lizzie. “Musician Taryn Southern on Composing Her New Album Entirely with AI.” The Verge, The Verge, 27 Aug. 2017</a></li>
                <li className="text"><a href="https://www.cloudpainter.com/">“An Artificially Intelligent Painting Robot.” Cloudpainter</a></li>
                <li className="text"><a href="https://genekogan.com/">Gene Kogan</a></li>
                <li className="text"><a href="https://www.thepaintingfool.com/index.html">The Painting Fool - A Computer Artist</a></li>
                <li className="text"><a href="https://computerhistory.org/blog/algorithmic-music-david-cope-and-emi/">“Algorithmic Music – David Cope and EMI.” CHM, 24 Sept. 2019</a></li>
            </ul>

        </div>
    );
}

export default Article2;