import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import LanguageContext from '../LanguageContext';
import '../App.css';

function Home() {
  const { language } = useContext(LanguageContext);

  const translations = {
    wip: {
      english: 'Work in Progress',
      french: 'Travail en Cours',
      spanish: 'Trabajo en Progreso'
    },
    welcome: {
      english: 'Welcome',
      french: 'Bienvenue',
      spanish: 'Bienvenido'
    },
    p1: {
      english: 'Feel free to ',
      french: "N'hésitez pas à ",
      spanish: 'No dude en '
    },
    p2: {
      english: 'download a copy of my resume',
      french: 'télécharger une copie de mon CV',
      spanish: 'descargar una copia de mi currículum'
    },
    p3: {
      english: ' or explore my ',
      french: ' ou à explorer mon ',
      spanish: ' o explorar mi '
    },
    p4: {
      english: 'interactive resume',
      french: 'CV interactif',
      spanish: 'currículum interactivo'
    },
  };

  return (
    <div className="homeContainer">
      <h2 className="title">{translations.welcome[language]}!</h2>
      <h2 className="wip">*** {translations.wip[language]} ***</h2>
      <p className="text" style={{ textAlign: "center" }}>{translations.p1[language]}<a href="/Louis Romier Resume.pdf" className="download-link" download>{translations.p2[language]}</a>{translations.p3[language]}<Link to="/InteractiveResume" className="download-link">{translations.p4[language]}</Link>.</p>
    </div >
  );
}

export default Home;