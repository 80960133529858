export const experiences = {
  'ETQ': {
    title: {
      english: 'Implementation Consultant at ETQ',
      french: 'Consultant en Implantation chez ETQ',
      spanish: 'Consultor de implementación en ETQ',
    },
    date: 'Feb 2023 - Present',
    keywords: 'SQL, Jython, Customer Relations',
    content: '',
    classification: {
      english: 'Work Experience',
      french: 'Expérience Professionnelle',
      spanish: 'Experiencia Laboral',
    },
    category: ['professional'],
    slugMap: 'ETQ',
    imageUrl: 'https://media.louisromier.com/Exp/ETQLogo.png'
  },
  'ETQ2': {
    title: {
      english: 'Technical Implementation Consultant at ETQ',
      french: 'Consultant en Implémentation Technique chez ETQ',
      spanish: 'Consultor de Implementación Técnica en ETQ',
    },
    date: 'Nov 2021 - Feb 2023',
    keywords: 'SQL, Jython, Customer Relations',
    content: '',
    classification: {
      english: 'Work Experience',
      french: 'Expérience Professionnelle',
      spanish: 'Experiencia Laboral',
    },
    category: ['professional'],
    slugMap: 'ETQ',
    imageUrl: 'https://media.louisromier.com/Exp/ETQLogo.png'
  },
  'Onda': {
    title: {
      english: 'Founder / Software Developer at Onda',
      french: 'Fondateur / Développeur de Logiciels chez Onda',
      spanish: 'Fundador / Desarrollador de Software en Onda',
    },
    date: 'Jan 2021 - Present',
    keywords: 'Developer, JS, React Native, Firebase, UI/UX',
    content: 'Worked under multiple job titles to establish Onda, a peer to peer surfboard rental company.',
    classification: {
      english: 'Work Experience',
      french: 'Expérience Professionnelle',
      spanish: 'Experiencia Laboral',
    },
    category: ['professional', 'personal'],
    slugMap: 'Onda',
    imageUrl: 'https://media.louisromier.com/Exp/OndaLogo.jpeg'
  },
  'AudioVisual': {
    title: {
      english: 'Audio Visual Explorations',
      french: 'Explorations Audiovisuelles',
      spanish: 'Exploraciones Audiovisuales',
    },
    date: '2023',
    keywords: 'Python, Audio Extraction, Image Manipulation',
    content: 'Content',
    classification: {
      english: 'Project',
      french: 'Projet',
      spanish: 'Proyecto',
    },
    category: ['personal', 'creative'],
    slugMap: 'AudioVisual',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'MachineLearning': {
    title: {
      english: 'Machine Learning Surf Judge',
      french: 'Juge de Surf en Apprentissage Automatique',
      spanish: 'Juez de Surf con Aprendizaje Automático',
    },
    date: '2023',
    keywords: 'TensorFlow, JS, Machine Learning, Data Collection, Data Standardization',
    content: 'Content',
    classification: {
      english: 'Project',
      french: 'Projet',
      spanish: 'Proyecto',
    },
    category: ['personal'],
    slugMap: 'MachineLearning',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'SurfCalendar': {
    title: {
      english: 'Surf Availability Calendar',
      french: 'Calendrier de Disponibilité de Surf',
      spanish: 'Calendario de Disponibilidad de Surf',
    },
    date: '2022',
    keywords: 'JS, Microsoft Azure API, Webscraping',
    content: 'Content',
    classification: {
      english: 'Project',
      french: 'Projet',
      spanish: 'Proyecto',
    },
    category: ['personal'],
    slugMap: 'SurfCalendar',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'Art': {
    title: {
      english: 'Art',
      french: 'Art',
      spanish: 'Arte',
    },
    date: 'Present',
    keywords: 'Oil,acryllic,prints,pastels',
    content: 'Oil, Acryllic, Prints, Charcoal',
    classification: {
      english: 'Passion Project',
      french: 'Projet Passion',
      spanish: 'Proyecto Pasión',
    },
    category: ['creative'],
    slugMap: 'Art',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'SurfboardShaping': {
    title: {
      english: 'Surfboard Shaping',
      french: 'Mise en Forme de la Planche de Surf',
      spanish: 'Dar Forma a la Tabla de Surf',
    },
    date: 'Present',
    keywords: 'Trial and Error',
    content: 'Content',
    classification: {
      english: 'Passion Project',
      french: 'Projet Passion',
      spanish: 'Proyecto Pasión',
    },
    category: ['creative'],
    slugMap: 'SurfboardShaping',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'Article3': {
    title: {
      english: 'Has The Isolation Economy Doomed The Sharing Economy?',
      french: "L’économie d’isolement a-t-elle Condamné l’économie du Partage?",
      spanish: '¿La Economía del Aislamiento ha Condenado a la Economía Colaborativa?',
    },
    date: 'Aug 2020',
    keywords: '',
    content: 'Content',
    classification: {
      english: 'Article',
      french: 'Article',
      spanish: 'Artículo',
    },
    category: ['personal'],
    slugMap: 'Article3',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'Article2': {
    title: {
      english: 'Artificial Intelligence as a Creative Tool',
      french: "L'intelligence Artificielle Comme Outil Créatif",
      spanish: 'La Inteligencia Artificial Como Herramienta Creativa',
    },
    date: 'June 2020',
    keywords: '',
    content: 'Content',
    classification: {
      english: 'Article',
      french: 'Article',
      spanish: 'Artículo',
    },
    category: ['personal'],
    slugMap: 'Article2',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'Article1': {
    title: {
      english: 'Navigating The Information Age',
      french: "Naviguer dans l’ère de l’information",
      spanish: 'Navegando Por la Era de la Información',
    },
    date: 'July 2020',
    keywords: '',
    content: 'Content',
    classification: {
      english: 'Article',
      french: 'Article',
      spanish: 'Artículo',
    },
    category: ['personal'],
    slugMap: 'Article1',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'University': {
    title: {
      english: 'Bachelor of Science in Business Administration',
      french: 'Baccalauréat ès Sciences en Administration des Affaires',
      spanish: 'Licenciatura en Ciencias en Administración de Empresas',
    },
    date: 'Aug 2016 - May 2020',
    keywords: 'Statistics, Finance, Operations, Marketing',
    content: 'Focus in Management Information Systems, Minor in Computer Science',
    classification: {
      english: 'Education',
      french: 'Éducation',
      spanish: 'Educación',
    },
    category: ['professional'],
    slugMap: 'University',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'UniversityMinor': {
    title: {
      english: 'Minor in Computer Science',
      french: 'Mineure en Informatique',
      spanish: 'Menor en Ciencias de la Computación',
    },
    date: 'Aug 2016 - 2020',
    keywords: 'Full Stack, C, JS, MEAN Stack Dev, Node, SQL',
    content: 'Content',
    classification: {
      english: 'Education',
      french: 'Éducation',
      spanish: 'Educación',
    },
    category: ['professional'],
    slugMap: 'University',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'PlatformConsultant': {
    title: {
      english: 'Platform Consultant at BASF Corporation',
      french: 'Consultant Plateforme chez BASF Corporation',
      spanish: 'Consultor de Plataforma en BASF Corporation',
    },
    date: 'Jan 2020 - Apr 2020',
    keywords: 'Network Effects, Governance, Architecture',
    content: 'Content',
    classification: {
      english: 'Internship',
      french: 'Pasantía',
      spanish: 'Stage',
    },
    category: ['professional'],
    slugMap: 'PlatformConsultant',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'MarketingInternship': {
    title: {
      english: 'Internship at the Royal Agricultural Society',
      french: 'Stage à la Royal Agricultural Society',
      spanish: 'Pasantía en la Real Sociedad Agrícola',
    },
    date: 'Jan 2019 - May 2019',
    keywords: 'Photography, Maketing, Outreach',
    content: 'Part of the marketing team covering the annual Royal Easter Show, the largest ticketed event in the southern hemisphere.',
    classification: {
      english: 'Internship',
      french: 'Pasantía',
      spanish: 'Stage',
    },
    category: ['professional'],
    slugMap: 'MarketingInternship',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  },
  'Photography': {
    title: {
      english: 'Photography',
      french: 'La Photographie',
      spanish: 'Fotografía',
    },
    date: 'Present',
    keywords: 'Film, Digital, Developing',
    content: 'Content',
    classification: {
      english: 'Passion Project',
      french: 'Projet Passion',
      spanish: 'Proyecto Pasión',
    },
    category: ['creative'],
    slugMap: 'Photography',
    imageUrl: 'https://media.louisromier.com/Art/Parque.jpg'
  }
};