import '../App.css';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function PlatformConsultant({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h2 className="title">{title[language]}</h2>
                <h3 className="date">{date}</h3>
            </div>
            <ul>
                <li className="text">
                    Collaborated with two other students and BASF executives to gather research and develop ideas for a platform BASF is looking to launch.
                </li>
                <li className="text">
                    Focus points were on the architecture, governance and managing network effects on the platform.
                </li>
                <li className="text">
                    Developed a 50 page in-depth recommendation covering how to launch the platform, what aspects of the platform to control, what to leave open, and how to avoid disintermediation.
                </li>
                <li className="text">
                    Designed an expanding platform plan to make sure the two-sided platform reaches acceptable adoption before expanding to multiple sides.
                </li>
                <li className="text">
                    Spent over 25 hours a week split up between conducting research and interviews.                </li>
            </ul>
        </div>
    );
}

export default PlatformConsultant;