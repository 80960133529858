import React, { useState } from 'react';

const ImageMosaic = ({ imageUrls = [] }) => {
    const [activeImage, setActiveImage] = useState(null);

    return (
        <div>
            <div className="mosaic-grid">
                {imageUrls.map((url, index) => (
                    <div
                        key={index}
                        className="mosaic-item"
                        style={{ backgroundImage: `url(${url})` }}
                        onClick={() => setActiveImage(url)}
                    />
                ))}
            </div>

            {activeImage && (
                <div className="lightbox" onClick={() => setActiveImage(null)}>
                    <div className="lightbox-content">
                        <img src={activeImage} alt="" />
                    </div>
                </div>
            )}

            <style jsx>{`
            .mosaic-grid {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
              gap: 10px;
            }
    
            .mosaic-item {
              width: 100%;
              height: 500px;
              background-size: cover;
              background-position: center;
              cursor: pointer;
            }
    
            .lightbox {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.8);
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1000;
            }
    
            .lightbox-content {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 90vh;
              width: 90vw;
            }
    
            .lightbox img {
              max-height: 100%;
              max-width: 100%;
              object-fit: contain;
            }
          `}</style>
        </div>
    );
}

export default ImageMosaic;

