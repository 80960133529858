import '../App.css';
import ImageMosaic from '../ImageMosaic.js';

function Art({ experiences }) {
  const exp = experiences["Art"];

  const artImages = [
    'https://media.louisromier.com/Art/Parque.jpg',
    'https://media.louisromier.com/Art/BaseOnTopOfTheWorld.jpg',
    'https://media.louisromier.com/Art/DaturasPerformingforJ-Trees.jpg',
    'https://media.louisromier.com/Art/Madonna.jpg',
    'https://media.louisromier.com/Art/Vallon-Pont-dArc.jpg',
    'https://media.louisromier.com/Art/SeashoreSeashells.jpg',
    'https://media.louisromier.com/Art/LesTroisPetitsCochons.jpg',
    'https://media.louisromier.com/Art/CawingCrows.jpg',
    'https://media.louisromier.com/Art/Venus.jpg'
  ];

  return (
    <div className="experience-container">
      <div className="titleRow">
        <h2 className="title">Art</h2>
        <h3 className="date">Present</h3>
      </div>
      <p className='text'>
        Some Oil, Acryllic and Pastel works.
      </p>
      <div className="gallery">
        <ImageMosaic imageUrls={artImages} />
      </div>
    </div>
  );
}

export default Art;
