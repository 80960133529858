import '../App.css';

function NoMatch() {
    return (
      <div style={{ padding: 20 }}>
        <h2 className="title">404: Page Not Found</h2>
        <p className="text">Woah woah woah, nothing to see here bucko</p>
      </div>
    );
  }

export default NoMatch;