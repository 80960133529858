import { BrowserRouter as Router, useRoutes, Link, Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useLayoutEffect, useRef, useContext } from 'react';
import { experiences } from './data/experiences.js';
import About from './pages/about.js';
import Home from './pages/home.js';
import ETQ from './experiences/ETQ.js';
import Onda from './experiences/Onda.js';
import PlatformConsultant from './experiences/PlatformConsultant.js';
import MarketingInternship from './experiences/MarketingInternship.js';
import University from './experiences/University.js';
import Photo from './experiences/photo.js';
import Art from './experiences/art.js';
import Shaping from './experiences/shaping.js';
import AudioVisual from './experiences/AudioVisual.js';
import SurfCalendar from './experiences/SurfCalendar.js';
import MachineLearning from './experiences/MachineLearning.js';
import Article1 from './experiences/Article1.js';
import Article2 from './experiences/Article2.js';
import Article3 from './experiences/Article3.js';
import NoMatch from './pages/noMatch.js';
import FilteredExpContext from './FilteredExpContext';
import LanguageContext from './LanguageContext';
import AudioContext from './AudioContext';
import CreativeContext from './CreativeContext';
import LanguageToggle from './LanguageToggle';
import ExploreMore from './ExploreMore';
import RadioButton from './RadioButton';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';

function ExperienceList() {
  const classificationColors = {
    "Work Experience": '#ECD9B2',
    "Project": "#BACD9B",
    "Passion Project": "#E87C7B",
    "Education": "#40A67C",
    "Article": "#14222F",
    "Internship": "#11473A",
  };
  const { language } = useContext(LanguageContext);
  const exps = React.useContext(FilteredExpContext);
  return (
    <div className="experiencesGrid">
      {exps.map(([slug, { title, date, classification, slugMap }]) => {
        const bgColor = classificationColors[classification['english']] || "#FFFFFF";  // default to white if no match

        return (
          <Link
            className="experience"
            to={`/InteractiveResume/${slugMap}`}
            key={slug}
            style={{ backgroundColor: bgColor }}>
            <div className="expText">
              <h3 className="exp">{date}</h3>
              <h3 className="exp">{classification[language]}</h3>
              <h2 className="exp">{title[language]}</h2>
            </div>
          </Link>
        );
      })}
    </div>
  );
}

function InteractiveResume() {
  const { language } = useContext(LanguageContext);
  const { slug } = useParams();
  const isPostSelected = Boolean(slug);
  const navigate = useNavigate();
  const [prof, setProf] = React.useState(false);
  const [creative, setCreative] = React.useState(false);
  const [personal, setPersonal] = React.useState(false);
  const [filteredExp, setFilteredExp] = React.useState(Object.entries(experiences));

  const handleProfChange = () => {
    setProf(prev => !prev);
    filterExp();
  };

  const handleCreativeChange = () => {
    setCreative(prev => !prev);
    filterExp();
  };

  const handlePersonalChange = () => {
    setPersonal(prev => !prev);
    filterExp();
  };

  React.useEffect(() => {
    filterExp(); // Refilter experiences whenever checkbox states change
  }, [prof, creative, personal]);

  const [selectedCategory, setSelectedCategory] = React.useState('all');

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    filterExp(e.target.value);
  };

  const filterExp = (category = 'all') => {
    if (category === 'all') {
      setFilteredExp(Object.entries(experiences));
    } else {
      setFilteredExp(Object.entries(experiences).filter(
        ([slug, experience]) => experience.category.includes(category)
      ));
    }
  };

  const translations = {
    resume: {
      english: 'Interactive Resume',
      french: 'CV Interactif',
      spanish: 'Currículum Interactivo'
    },
    resumeHome: {
      english: 'Interactive Resume Home',
      french: 'CV Interactif',
      spanish: 'Currículum Interactivo Inicio'
    },
    all: {
      english: 'All',
      french: 'Tout',
      spanish: 'Todo'
    },
    prof: {
      english: 'Professional',
      french: 'Professionnel',
      spanish: 'Profesional'
    },
    personal: {
      english: 'Personal',
      french: 'Personnel',
      spanish: 'Personal'
    },
    creative: {
      english: 'Creative',
      french: 'Créatif',
      spanish: 'Creativo'
    },
  }

  return (
    <div className="resumeContainer">
      {isPostSelected ? (
        // Show back button if a post is selected
        <div className="back-container">
          <div className="back-toggle">
            <span onClick={() => navigate("/InteractiveResume")}>
              {translations.resumeHome[language]}
            </span>
          </div>
        </div>
      ) : (
        // Otherwise show checkboxes
        <>
          <h2 className="titleIR">{translations.resume[language]}</h2>
          <div className="radioButtonContainer">
            <RadioButton
              label={translations.all[language]}
              value="all"
              checked={selectedCategory === 'all'}
              onChange={handleCategoryChange}
            />
            <RadioButton
              label={translations.prof[language]}
              value="professional"
              checked={selectedCategory === 'professional'}
              onChange={handleCategoryChange}
            />
            <RadioButton
              label={translations.personal[language]}
              value="personal"
              checked={selectedCategory === 'personal'}
              onChange={handleCategoryChange}
            />
            <RadioButton
              label={translations.creative[language]}
              value="creative"
              checked={selectedCategory === 'creative'}
              onChange={handleCategoryChange}
            />
          </div>
        </>
      )}
      <FilteredExpContext.Provider value={filteredExp}>
        <Outlet />
      </FilteredExpContext.Provider>
    </div>
  );
}

function Experience() {
  const { language } = useContext(LanguageContext);
  const { slug } = useParams();
  const exp = experiences[slug];

  const translations = {
    notExist: {
      english: "The experience you've requested doesn't exist.",
      french: "L'expérience que vous avez demandée n'existe pas.",
      spanish: 'La experiencia que has solicitado no existe.'
    },
  }

  if (!exp) {
    return <span>{translations.notExist[language]}</span>;
  }

  if (slug === "Photography") {
    return <>
      <Photo experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "Art") {
    return <>
      <Art experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "SurfboardShaping") {
    return <>
      <Shaping experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "ETQ") {
    return <>
      <ETQ experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "Onda") {
    return <>
      <Onda experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "PlatformConsultant") {
    return <>
      <PlatformConsultant experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "MarketingInternship") {
    return <>
      <MarketingInternship experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "University") {
    return <>
      <University experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "Article1") {
    return <>
      <Article1 experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "Article2") {
    return <>
      <Article2 experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "Article3") {
    return <>
      <Article3 experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "AudioVisual") {
    return <>
      <AudioVisual experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "SurfCalendar") {
    return <>
      <SurfCalendar experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  if (slug === "MachineLearning") {
    return <>
      <MachineLearning experiences={exp} />
      <ExploreMore experiences={Object.entries(experiences)} />
    </>;
  }

  const { title, date, keywords, content } = exp;
  return (
    <>
      <div className="experience-container">
        <p className="text">Skills: {keywords}</p>
        <div className="titleRow">
          <h2 className="title">{title[language]}</h2>
          <h3 class="date">{date}</h3>
        </div>
        <p className="text">{content}</p>
      </div>
      <ExploreMore experiences={Object.entries(experiences)} />
    </>
  );
}

function Routes() {
  const element = useRoutes([
    { path: "/", element: <Home /> },
    {
      path: "/InteractiveResume",
      element: <InteractiveResume />,
      children: [
        { index: true, element: <ExperienceList /> },
        { path: ":slug", element: <Experience /> }
      ],
    },
    { path: "/about", element: <About /> },
    { path: "*", element: <NoMatch /> }
  ]);
  return element;
}

function NavLink({ to, children }) {
  const location = useLocation();
  const isActive = to === "/"
    ? location.pathname === to
    : location.pathname.startsWith(to);

  return (
    <Link to={to} className={isActive ? 'active' : ''}>
      {children}
    </Link>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (null);
}

function getRandomColor() {
  const getRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

  const randomRed = getRandomValue(100, 200);
  const randomGreen = getRandomValue(100, 200);
  const randomBlue = getRandomValue(100, 200);
  /*
  const randomRed = Math.floor(Math.random() * 256);
  const randomGreen = Math.floor(Math.random() * 256);
  const randomBlue = Math.floor(Math.random() * 256);*/

  // Calculate the new color values % closer to white
  const r = Math.floor(randomRed * 0.5 + 255 * 0.5);
  const g = Math.floor(randomGreen * 0.5 + 255 * 0.5);
  const b = Math.floor(randomBlue * 0.5 + 255 * 0.5);

  const solid = `rgba(${r},${g},${b},1)`;
  const translucent = `rgba(${r},${g},${b},0.8)`;

  return [solid, translucent];
}

function getRandomPercentage() {
  return Math.floor(Math.random() * 101);
}

function App() {
  const [language, setLanguage] = useState('english');
  const [skeletonMode, setSkeletonMode] = useState(false);
  const [creativeMode, setCreativeMode] = useState(false);
  const audioRef = useRef(null);
  const audioFiles = [
    "https://media.louisromier.com/Music/Acapulco.mp3",
    "https://media.louisromier.com/Music/Children.mp3",
    "https://media.louisromier.com/Music/Bob.mp3",
    "https://media.louisromier.com/Music/LaMer.mp3",
    "https://media.louisromier.com/Music/AllMyJoyAllMyPain.mp3",
    "https://media.louisromier.com/Music/ATearForEddie.mp3",
    "https://media.louisromier.com/Music/NowThatWeveFoundLove.mp3",
    "https://media.louisromier.com/Music/AHorseWithNoName.mp3",
    "https://media.louisromier.com/Music/EmmenezMoi.mp3",
    "https://media.louisromier.com/Music/EverybodyLovesTheSunshine.mp3",
    "https://media.louisromier.com/Music/IdLoveToChangeTheWorld.mp3",
    "https://media.louisromier.com/Music/IllBeThere.mp3",
    "https://media.louisromier.com/Music/Ciclo.mp3",
    "https://media.louisromier.com/Music/WorkingClassHero.mp3",
    "https://media.louisromier.com/Music/LaCasaDelSolNaciente.mp3"
  ];

  useEffect(() => {
    if (creativeMode) {
      // Shuffle and choose a random audio file
      const randomFile = audioFiles[Math.floor(Math.random() * audioFiles.length)];
      audioRef.current.src = randomFile;
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [creativeMode]);

  // const [lavaColor1, translucentLavaColor1] = getRandomColor();
  const [lavaColor2, translucentLavaColor2] = getRandomColor();
  const [lavaColor3, translucentLavaColor3] = getRandomColor();
  //document.documentElement.style.setProperty('--lava-color1', lavaColor1);
  document.documentElement.style.setProperty('--lava-color2', lavaColor2);
  document.documentElement.style.setProperty('--lava-color3', lavaColor3);

  const translations = {
    home: {
      english: 'Home',
      french: 'Accueil',
      spanish: 'Inicio'
    },
    resume: {
      english: 'Interactive Resume',
      french: 'CV Interactif',
      spanish: 'Currículum Interactivo'
    },
    about: {
      english: 'About',
      french: 'A Propos de Moi',
      spanish: 'Sobre'
    },
    uiux: {
      english: 'UI/UX Skeleton Mode',
      french: 'Mode Squelette UI/UX',
      spanish: 'Modo Esqueleto UI/UX'
    },
    creativeMode: {
      english: 'Creative Mode',
      french: 'Mode Creative',
      spanish: 'Modo Creativo'
    },
  }

  return (
    <div className={`mainContainer ${skeletonMode ? "skeleton-mode" : ""} ${creativeMode ? "blob" : ""} ${creativeMode ? "lava-lamp-background" : ""}`}>
      <div className="skeleton-toggle">
        <input
          type="checkbox"
          id="skeletonMode"
          className="skeleton-input"
          checked={skeletonMode}
          onChange={() => setSkeletonMode(prev => !prev)}
        />
        <label htmlFor="skeletonMode" className="skeleton-label">{translations.uiux[language]}</label>
      </div>
      <div className="creative-toggle">
        <input
          type="checkbox"
          id="creativeMode"
          className="creative-input"
          checked={creativeMode}
          onChange={() => {

            if (!creativeMode) {
              const [lavaColor1, translucentLavaColor1] = getRandomColor();
              const [lavaColor2, translucentLavaColor2] = getRandomColor();
              const [lavaColor3, translucentLavaColor3] = getRandomColor();
              document.documentElement.style.setProperty('--lava-color1', lavaColor1);
              document.documentElement.style.setProperty('--lava-color1-translucent', translucentLavaColor1);
              document.documentElement.style.setProperty('--lava-color2', lavaColor2);
              document.documentElement.style.setProperty('--lava-color3', lavaColor3);
            } else {
              document.documentElement.style.setProperty('--lava-color1', 'rgba(236, 217, 178, 1)');
              document.documentElement.style.setProperty('--lava-color1-translucent', 'rgba(236, 217, 178, 0.25)');
              document.documentElement.style.setProperty('--lava-color2', 'rgba(255, 255, 255, 1)');
              document.documentElement.style.setProperty('--lava-color3', 'rgba(255, 255, 255, 1)');
            }

            const percentage1 = getRandomPercentage() / 3;
            const percentage2 = getRandomPercentage() / 2;
            const percentage3 = getRandomPercentage();
            const percentage4 = getRandomPercentage();
            const percentage5 = getRandomPercentage();
            const percentage6 = getRandomPercentage();
            const percentage7 = getRandomPercentage();

            document.documentElement.style.setProperty('--lava-percentage1', `${percentage1}%`);
            document.documentElement.style.setProperty('--lava-percentage2', `${percentage2}%`);
            document.documentElement.style.setProperty('--lava-percentage3', `${percentage3}%`);
            document.documentElement.style.setProperty('--lava-percentage4', `${percentage4}%`);
            document.documentElement.style.setProperty('--lava-percentage5', `${percentage5}%`);
            document.documentElement.style.setProperty('--lava-percentage6', `${percentage6}%`);
            document.documentElement.style.setProperty('--lava-percentage7', `${percentage7}%`);

            setCreativeMode(prev => !prev);
          }}
        />
        <label htmlFor="creativeMode" className="creative-label">
          {translations.creativeMode[language]}<i className="fa fa-volume-up volume-icon"></i>
        </label>
      </div>
      <CreativeContext.Provider value={[creativeMode, setCreativeMode]}>
        <LanguageContext.Provider value={{ language, setLanguage }}>
          <AudioContext.Provider value={audioRef}>
            <Router>
              <div className="nav-content">
                <Link to="/" className="logo-link">
                  <img src="/logo.png" alt="Logo" id="nav-logo" />
                </Link>
                <nav>
                  <div className="nav-links">
                    <ScrollToTop />
                    <NavLink to="/">
                      {translations.home[language]}
                    </NavLink>
                    <NavLink to="/InteractiveResume">
                      {translations.resume[language]}
                    </NavLink>
                    <NavLink to="/about">
                      {translations.about[language]}
                    </NavLink>
                  </div>
                </nav>
              </div>
              <Routes />
              <LanguageToggle />
            </Router>
            <audio ref={audioRef} loop />
          </AudioContext.Provider>
        </LanguageContext.Provider>
      </CreativeContext.Provider>
    </div>
  );
}

export default App;