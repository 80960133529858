import '../App.css';

function Photo({ experiences }) {
  const exp = experiences["Photography"];

  return (
    <div className="experience-container">
      <div className="titleRow">
        <h2 className="title">Photography</h2>
        <h3 className="date">Present</h3>
      </div>
      <p className='text'>
        My journey with photography started in my youth, ignited by the simple act of taking my mother's digital camera to the beach. While waves too formidable kept me ashore, I captured surfers in action, later selling them their own snapshots as they wrapped up their sessions. My high school, <a href="https://www.ahschool.com/" target="_blank">American Heritage School</a>, further nurtured this passion, introducing me to the mesmerizing world of film photography. It was there that I immersed myself in the intricate process—from the moment of capture, to development, and finally, darkroom printing. Every phase required deliberate choices.
      </p>
      <p className='text'>
        The accolades I received in high school from local to national photo contests stirred in me thoughts of how best to harness my photographic skill. Leveraging it, I reached out to various publications, securing opportunities to shoot music events. Given Miami's vibrant culture, I soon found myself photographing prominent artists like <a href="https://open.spotify.com/artist/0Y5tJX1MQlPlqiwlOH1tJY?si=uY3VMe2uRRKxxXzw7z4wXw" target="_blank">Travis Scott</a>, <a href="https://open.spotify.com/artist/699OTQXzgjhIYAHMy9RyPD?si=8MxsHoG0Rzy6UnMEoeUeng" target="_blank">Playboi Carti</a> at Miami's <a href="https://www.rollingloud.com/" target="_blank">Rolling Loud Festival</a>, and the likes of <a href="https://open.spotify.com/artist/4x7gxsrTH3gThvSKZPPwaQ?si=an-Bw8HxQjWLHqvHUWEJuw" target="_blank">Chuckie</a> and <a href="https://open.spotify.com/artist/4D75GcNG95ebPtNvoNVXhz?si=UAheq43iQxq3YiTQSpvpyg" target="_blank">Afrojack</a> during the iconic <a href="https://miamimusicweek.com/" target="_blank">Miami Music Week</a>. At these events, I often found myself navigating the crowd and backstage areas, assertively positioning myself to get the perfect shot.
      </p>
      <p className='text'>
        Simultaneously, I ventured into editorial fashion photography for <a href="https://seajasper.com/" target="_blank">Sea Jasper</a>, my mother's sustainable fashion brand, and collaborated on personal projects with emerging Miami artist, <a href="https://open.spotify.com/artist/5AflrDLKnIrldZeepeKFAc?si=ZRpBVe1oQEyD4Z0_7VTOLQ" target="_blank">Dantes</a>. This work earned me features in <a href="http://voyagemia.com/interview/meet-virginie-fallon-romier-sea-jasper-miami/" target="_blank">magazines</a>, blogs, <a href="https://open.spotify.com/track/6hIBwULENUuqgfv3R27uHj?si=QEbgKFKYSMCZ6tKrthdJqw" target="_blank">Spotify covers with over a million plays</a>, and even on <a href="https://www.youtube.com/watch?v=DAGAQ1Hz_Eo" target="_blank">University of Miami’s award-winning cable TV channel</a>.
      </p>
      <p className='text'>
        In recent times, I've chosen to step back from commercial photography. My focus has shifted to film photography, allowing me the freedom to shoot as my heart desires and to deeply savor the entire film process. Today, you'd often find me exploring unfamiliar terrains with a film camera, letting my curiosity lead the way and later reminiscing the adventures through the photos I've taken.
      </p>
      <div className="gallery">
        {/* Render images or other specific content */}
      </div>
    </div>
  );
}

export default Photo;


