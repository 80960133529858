import '../App.css';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function SurfCalendar({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h1 className="title">{title[language]}</h1>
                <h3 className="date">{date}</h3>
            </div>
            <p className='text'>
                Balancing work with leisure has always been an art, but with the Surf & Schedule Syncer, it becomes a science. Utilizing the robust capabilities of the Microsoft Azure API, this ingenious React application fetches my calendar details to ensure I never miss a meeting. But the brilliance doesn't stop there; an efficient web scraper dives into the realms of the web to fetch the current surf forecast for my preferred local surf break.
            </p>
            <p className='text'>
                The result? An intelligently crafted dashboard that aligns the rhythm of the waves with the pulse of my work schedule. On days when the ocean roars and my calendar shows a clear slate, it's a sign from the digital deities to grab my board and hit the waves. Surf & Schedule Syncer is not just an application; it's the epitome of work-life harmony for every surf enthusiast tethered to the ebb and flow of modern-day commitments.

            </p>
        </div >
    );
}

export default SurfCalendar;