import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageCarousel = ({ imageUrls = [] }) => {
    const imageWidth = 500;  // Width of each image, adjust this value based on your image width
    const [containerWidth, setContainerWidth] = useState(window.innerWidth);
    const slidesToShowValue = Math.floor(containerWidth / imageWidth);

    useEffect(() => {
        const handleResize = () => {
            setContainerWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShowValue,
        slidesToScroll: slidesToShowValue,
        arrows: true
    };

    return (
        <div style={{ width: '80%', margin: '0 auto' }}>
            <Slider {...settings}>
                {imageUrls.map((url, index) => (
                    <div key={index}>
                        <img src={url} alt={`slide-${index}`} style={{ width: '95%' }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default ImageCarousel;