import '../App.css';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function MarketingInternship({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h2 className="title">{title[language]}</h2>
                <h3 className="date">{date}</h3>
            </div>
            <ul>
                <li className="text">
                    Mastered the use of their marketing database used to spread our commercial photographs, and track in-depth social media influence
                </li>
                <li className="text">
                    Photographed the Royal Easter Show, the biggest ticketed event in the southern hemisphere, to be used for marketing for subsequent shows.
                </li>
            </ul>
        </div>
    );
}

export default MarketingInternship;