import React, { useEffect, useContext } from 'react';
import '../App.css';
import LanguageContext from '../LanguageContext';
import AudioContext from '../AudioContext';
import CreativeContext from '../CreativeContext';

function AudioVisual({ experiences }) {
    const { language } = useContext(LanguageContext);
    const audioRef = useContext(AudioContext);
    const [creativeMode, setCreativeMode] = useContext(CreativeContext);
    const { title, date, keywords, content } = experiences;

    const handleMouseOver = (e) => {
        e.target.muted = false; // Unmute the video
        if (creativeMode) { // Check if the creative mode is on
            audioRef.current.pause();
        }
    };

    const handleMouseOut = (e) => {
        e.target.muted = true; // Mute the video
        if (creativeMode) { // Check if the creative mode is on
            audioRef.current.play();
        }
    };
    useEffect(() => {
        const video1 = document.getElementById("clean");
        const video2 = document.getElementById("intense");

        if (!video1 || !video2) return; // Ensure both videos exist

        const handlePlay1 = () => video2.play();
        const handlePause1 = () => video2.pause();
        const handleSeeked1 = () => video2.currentTime = video1.currentTime;

        const handlePlay2 = () => video1.play();
        const handlePause2 = () => video1.pause();
        const handleSeeked2 = () => video1.currentTime = video2.currentTime;

        video1.addEventListener("play", handlePlay1);
        video1.addEventListener("pause", handlePause1);
        video1.addEventListener("seeked", handleSeeked1);

        video2.addEventListener("play", handlePlay2);
        video2.addEventListener("pause", handlePause2);
        video2.addEventListener("seeked", handleSeeked2);

        // Cleanup
        return () => {
            video1.removeEventListener("play", handlePlay1);
            video1.removeEventListener("pause", handlePause1);
            video1.removeEventListener("seeked", handleSeeked1);

            video2.removeEventListener("play", handlePlay2);
            video2.removeEventListener("pause", handlePause2);
            video2.removeEventListener("seeked", handleSeeked2);
        };

    }, []);

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h1 className="title">{title[language]}</h1>
                <h3 className="date">{date}</h3>
            </div>
            <p className='text'>
                This sophisticated program harnesses the power of Python to synergize visual and auditory experiences. Users provide an image or video alongside a musical track. The resulting output is a dynamically transformed visual that spans the length of the music input. Here's where it gets intriguing: each frame of the output video undergoes unique transformations determined by the nuances of the musical track. By employing advanced audio extraction techniques, the program deciphers various audio elements such as brightness levels, tonal variations, and more from diversified audio streams. These streams are methodically processed through high-frequency, low-frequency, and other specialized filters. The outcome? A breathtaking blend of visuals that dance in tandem with the very essence of the music, offering an immersive experience that transcends traditional audio-visual presentations.
            </p>
            <div className="video-section">
                <h2 className="title">Example: (Does not work on Safari or IOS currently)</h2>
                <video controls loop muted autoPlay
                    width="640" height="480"
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}>
                    <source src="https://media.louisromier.com/AV/treesPiano.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}

export default AudioVisual;