import '../App.css';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function Article1({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    return (
        <div className="experience-container">

            <div className="titleRow">
                <h1 className="title">{title[language]}</h1>
                <h3 className="date">{date}</h3>
            </div>
            <p className="text">Information at its most basic point can be viewed as “knowledge obtained through investigation, study, or instruction.” Throughout society, we have always been analyzing our environment to deduce valuable knowledge from our observations, but it has never been as widespread as nowadays. A combination of the increased feasibility to spread information, an insatiable production of information and an inability to verify all this information is suffocating us.</p>

            <h2 className="title">From Data to Information to Knowledge</h2>
            <p className="text">The logical path of how we create information is to record observations in order to create data. This data can then become information if it is transformed. Transformations include combinations of how the data is calculated, condensed and categorized, the context of how it is gathered and whether corrections have been made to it. Once our data has been transformed, it is regarded as information. It then yields the possibility to become “actionable information” or knowledge. This can only be done once a cause and effect relationship can be extrapolated from the info. This ability of data to become knowledge explains the exponential rise in the creation and spread of information. This marks such a change in society that we have moved past the industrial age into the digital or information age. The digital age is characterized by information and widespread computerization thanks to the creation of transistors used as building blocks to digital electronics.</p>

            <h2 className="title">Information Theory</h2>
            <p className="text">Claude Shannon was a mathematician who defined the concept of information in 1948.Shannon’s information theory was way ahead of its time, now being a crucial component in fields ranging from neurology and computer science to behavioral science. The theory’s main findings involve parameters and rules governing how information is transmitted through communication channels. It also describes methodologies to separate real information from noise to figure out the channel’s capacity. This idea of separating real information from noise has never been more relevant as it is now in our information dependent society.</p>
            <p className="text">As the amount of data, information and knowledge increases at exponential rates, we are inundated with vastly more noise within the channels we rely on. In just the last two years, over 90% of the world’s data has been created. The key point in this statement is that only the data has been created, there is still more work that needs to be done to it to become information and subsequently knowledge. This data’s unreliability creates a problem when it is spread to large numbers of people, some of whom are reliant on this data for knowledge. In just the past ten years, the adoption of the internet has more than doubled worldwide from 28.7 to 59.6% today. All of these users now need to be responsible for what data they base their knowledge off of. The efficient sorting of data has become an essential personal skill to master. But with the average person taking in five times as much data in a day as they did 25 years ago, how difficult has this sorting process become?</p>

            <h2 className="title">Inabilities to Efficiently Sort Vast Amounts of Data</h2>
            <p className="text">The effects of this increase in data availability can be seen in our society today. For one, people are having much more difficulty in separating trivial and important information. Which leads to people coming to conclusions from potentially misleading data. This effect is only reinforced when people who have come to similar conclusions are able to communicate and congregate online, reinforcing their flawed conclusions. This has had a tremendous impact on our society recently, leading to massive distrust of the information traditionally conveyed by the media in favor of whatever information an individual wants to believe online. Another effect of our inability to process this much data has been a significant rise in cases of ADHD in children. According to the CDC, there has been an increase of 42% in eight years in cases of ADHD in children within four and 17 years old. The inability of children to learn to efficiently sort through data before they are given access to enormous amounts of it is probably a partial cause for this significant rise in cases.</p>

            <h2 className="title">Following our Brain’s Speed Limit</h2>
            <p className="text">It has been independently discovered by psychologists Mihaly Csikszentmihalyi and Bells engineer Robert Lucky that our brain is limited to processing just 120 bits of information per second. To put this number into perspective, having a conversation with someone already requires 60 bits per second. This involves the process of hearing, thinking, speaking and recognizing body language and emotions. Due to this mental speed limit of sorts we need to constantly prioritize what we want within a practically infinite amount of information. Back in 1964, an American social scientist by the name of Bertram Gross coined a term called, “information overload.” He described this as when the amount of inputs a man or machine takes in exceeds its processing capacity. We are now in a society where information overload has become common, that we need to create new efficiencies to deal with.</p>
            <p className="text">Our brain already undertakes this process of efficiently sorting through information subconsciously. For example, it is able to prioritize aspects of our vision above others in order to efficiently allocate processing power elsewhere. It compresses visual information by prioritizing sharp curves and corners. From these key parts of the image, the brain then fills in the remaining blanks with great accuracy. This is just one of the ways we subconsciously sort through information.</p>

            <h2 className="title">Efficient Processing Spectrum</h2>
            <p className="text">The next question is, how can we consciously copy this process when dealing with large amounts of information. Our varying abilities to distinguish between information backed by logic and flawed information places us on a spectrum. Mihaly Csikszentmihalyi described a state that would occur on one end of the spectrum where individuals are unable to process information efficiently, being in a state of psychicentropy. Psychicentropy is described as a condition where attention is unable to order incoming information. In extreme cases this state leads to conditions such as schizophrenia</p>
            <p className="text">At the opposite end of this spectrum is when we are able to process information as efficiently as possible, reaching a state of flow. This state was first described in Daoist philosophy with how a butcher worked cutting apart livestock as if he was on autopilot. In other words, the state of flow allowed the butcher to be fully allocating his processing capacity to the task at hand, efficiently processing all the information available to him. Flow was then expounded upon by Mihaly Csikszentmihalyi. Csikszentmihalyi described this state with the greek word autotelic, meaning an activity whose only goal is to be experienced. A defining aspect of this was that the activity has to allow for opportunities for action and challenges. </p>

            <h2 className="title">Changing an Evolutionary Trend</h2>
            <p className="text">Ideally, we should strive to remain in a state of flow for as much amount of time as possible. Throughout the agricultural and industrial age, we have been able to evolve in large part due to being able to constantly increase efficiency and thus time we spend in a state of flow. During the hunter gatherer societies, a very small group would be responsible for all the knowledge they needed to survive. But, once efficient methods of agriculture were discovered, individuals would need to know much less knowledge as food could be bought from farmers now. The effect of this began a trend of outsourcing information to allocate our processing capacity to have a chance to reach a state of flow in our desired activity. Then as the industrial revolution came, the trend continued and people could do even more while retaining less knowledge as many goods could now be readily bought, and travel was becoming increasingly accessible to all. But now we have reached a potential limit in this trend of outsourcing knowledge in order to increase our quality of life. </p>
            <p className="text">Now that exponentially larger amounts of information are essentially outsourced to computers, the goal has shifted from wanting to outsource more to processing it more efficiently. This efficient processing has grown more important with the disproportionate amount of flawed information available online. This is potentially a point in our history where humans will need to have a conscious hand in choosing how we evolve. Our biggest problem to solve in the information age is how to efficiently sort and process information. Some experts believe a potential solution could be found in artificial intelligence. AI has a chance to bring in a new age where humans rely substantially more on computers to help us efficiently navigate through information.</p>

            <h2 className="title">References</h2>
            <ul>
                <li className="text"><a href="https://www.telegraph.co.uk/">Alleyne, Richard. “Welcome to the Information Age - 174 Newspapers a Day.” The Telegraph, Telegraph Media Group, 11 Feb. 2011</a></li>
                <li className="text"><a href="https://www.cdc.gov/">Centers for Disease Control and Prevention, Centers for Disease Control and Prevention</a></li>
                <li className="text"><a href="www.sciencedaily.com/releases/2011/02/110210164155.htm">“JPEG for the Mind: How the Brain Compresses Visual Information.” ScienceDaily, ScienceDaily, 11 Feb. 2011</a></li>
                <li className="text"><a href="www.pnas.org/content/117/15/8391">Honig, Maija, et al. “Humans Incorporate Trial-to-Trial Working Memory Uncertainty into Rewarded Decisions.” PNAS, National Academy of Sciences, 14 Apr. 2020.</a></li>
                <li className="text">“What Is the Memory Capacity of a Human Brain?: CNS West.” Clinical Neurology Specialists, 13 Mar. 2020, “What Is the Memory Capacity of a Human Brain?: CNS West.” Clinical Neurology Specialists, 13 Mar. 2020</li>
                <li className="text"><a href="www.forbes.com/sites/bernardmarr/2018/05/21/how-much-data-do-we-create-every-day-the-mind-blowing-stats-everyone-should-read/">Marr, Bernard. “How Much Data Do We Create Every Day? The Mind-Blowing Stats Everyone Should Read.” Forbes, Forbes Magazine, 5 Sept. 2019</a></li>
                <li className="text"><a href="www.internetworldstats.com/emarketing.htm">“Internet Growth Statistics 1995 to 2019.” Internet World Stats - Usage and Population Statistics, 7 June 2020</a></li>
                <li className="text"><a href="www.khanacademy.org/computing/computer-science/informationtheory/info-theory/v/intro-information-theory">“What Is Information Theory? (Video).” Khan Academy, Khan Academy, 28 Apr. 2014</a></li>
                <li className="text"><a href="www.sciencedirect.com/topics/neuroscience/information-theory">Martignon, L. “Information Theory.” Information Theory - an Overview | ScienceDirect Topics, 2001</a></li>
                <li className="text"><a href="www.wired.com/insights/2014/06/beyond-information-age/">Birkinshaw, Julian. “Beyond the Information Age.” Wired, Conde Nast, 7 Aug. 2015</a></li>
                <li className="text"><a href="www.merriam-webster.com/dictionary/information">“Information.” Merriam-Webster, Merriam-Webster</a></li>
                <li className="text"><a href="www.1843magazine.com/features/can-we-escape-from-information-overload">Lamont, Tom. “Can We Escape from Information Overload?” 1843, 22 May 2020</a></li>
                <li className="text"><a href="www.researchgate.net/publication/229099904_Working_Knowledge_How_Organizations_Manage_What_They_Know">Prusak, Lawrence, and Thomas H Davenport. “(PDF) Working Knowledge: How Organizations Manage What They Know.” ResearchGate, 1998</a></li>
                <li className="text"><a href="www.addrc.org/adhd-numbers-facts-statistics-and-you/">Holland, Kimberly, and Elsbeth Riley. “ADHD Numbers: Facts, Statistics, and You.” ADD Resource Center, 24 Oct. 2017</a></li>
                <li className="text">Csikszentmihalyi, Mihaly. The Collected Works of Mihaly Csikszentmihalyi. Springer, 2014</li>
                <li className="text">Verdu, Sergio. IEEE Transactions on Information Theory. Institute of Electrical and Electronics Engineers, 1963</li>
                <li className="text">Harari, Yuval Noah. Sapiens: A Brief History of Humankind. Vintage, 2019</li>
            </ul>
        </div>
    );
}

export default Article1;