import { Link } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import LanguageContext from './LanguageContext';

function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowWidth;
}

function getRandomExperiences(experiences, count) {
    let indices = Array.from({ length: experiences.length }, (_, i) => i);
    let randomIndices = [];

    for (let i = 0; i < count && i < experiences.length; i++) {
        let randomIndex = Math.floor(Math.random() * indices.length);
        randomIndices.push(indices[randomIndex]);
        indices.splice(randomIndex, 1);  // remove used index
    }

    return randomIndices.map(index => experiences[index]);
}

function ExploreMore(props) {
    const classificationColors = {
        "Work Experience": '#ECD9B2',
        "Project": "#BACD9B",
        "Passion Project": "#E87C7B",
        "Education": "#40A67C",
        "Article": "#14222F",
        "Internship": "#11473A",
    };
    const { language } = useContext(LanguageContext);
    const allExperiences = props.experiences;  // You need to pass the full experiences list to this component as props
    const windowWidth = useWindowWidth();

    const experienceWidth = 310;
    const experienceCount = Math.floor(windowWidth / experienceWidth);
    const randomExperiences = getRandomExperiences(allExperiences, experienceCount);

    return (
        <div className="exploreMore">
            <h3 className="exploreTitle">Explore More</h3>
            <div className="experiencesGrid">
                {randomExperiences.map(([slug, { title, date, classification, slugMap }]) => {
                    const bgColor = classificationColors[classification['english']] || "#FFFFFF";  // default to white if no match

                    return (
                        <Link className="experience" to={`/InteractiveResume/${slugMap}`} key={slug}
                            style={{ backgroundColor: bgColor }}>

                            <div className="expText">
                                <h3 className="exp">{date}</h3>
                                <h3 className="exp">{classification[language]}</h3>
                                <h2 className="exp">{title[language]}</h2>
                            </div>


                        </Link>
                    );
                })}
            </div>
        </div >
    );
}

export default ExploreMore;