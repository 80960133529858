import React, { useState, useContext } from 'react';
import LanguageContext from './LanguageContext';
import './App.css';

function LanguageToggle() {
    const { language, setLanguage } = useContext(LanguageContext);

    const [currentLang, setCurrentLang] = useState('english');

    const setLanguageFunction = (lang) => {
        setCurrentLang(lang);
        setLanguage(lang)
        // Add your language switching logic here.
    };

    const translations = {
        english: {
            english: 'English',
            french: 'Anglais',
            spanish: 'Inglés'
        },
        french: {
            english: 'French',
            french: 'Français',
            spanish: 'Francés'
        },
        spanish: {
            english: 'Spanish',
            french: 'Espagnol',
            spanish: 'Espagñol'
        }
    }

    return (
        <div className="language-toggle" id="language-toggle">
            <span
                onClick={() => setLanguageFunction('english')}
                className={currentLang === 'english' ? 'active' : ''}
            >
                {translations.english[language]}
            </span>
            <span
                onClick={() => setLanguageFunction('french')}
                className={currentLang === 'french' ? 'active' : ''}
            >
                {translations.french[language]}
            </span>
            <span
                onClick={() => setLanguageFunction('spanish')}
                className={currentLang === 'spanish' ? 'active' : ''}
            >
                {translations.spanish[language]}
            </span>
        </div>
    );
}

export default LanguageToggle;